<!--
  文件列表页面
-->
<template>
  <div class="body">
    <div class="fileList">
      <div class="fileList_content">
        <div class="fileList_top">
          <div class="top_tltle">
            <!--            文件-->
            <div v-if="fileCur < 4">{{ $t("FL") }}</div>
            <div v-if="fileCur == 4" class="file-dataBase">
              <!--            文件 FL-->
              <span @click="jumpTab({ idx: 0 })">{{ $t("FL") }}</span>
              <span
                v-for="(item, index) in tabArr"
                :key="index"
                @click="jumpTab(item)"
              >
                > {{ item.name }}</span
              >
            </div>
            <div>{{ labelTitle }}</div>
            <!--        个项目    label.file.items-->
            <div v-if="fileCur < 4">
              {{ page.total + $t("label.file.items") }}
            </div>
            <div v-if="fileCur == 4">
              {{ libLength + $t("label.file.items") }}
            </div>
          </div>
          <div class="right_infos">
            <div class="top_search" v-if="fileCur < 4">
              <!--             列表内搜索 label.search-->
              <el-input
                :placeholder="$t('vue_label_commonobjects_view_searchthisview')"
                suffix-icon="el-icon-search"
                v-model="searchInput"
                @keyup.enter.native="fileSelectSearch"
                clearable
              >
              </el-input>
            </div>
            <div class="top_butts">
              <!--              上传文件 label.file.uploadfiles-->
              <div v-if="fileCur < 4" @click="handleUpload">
                {{ $t("label.file.uploadfiles") }}
              </div>
              <!--              新建文件库-->
              <div v-if="fileCur == 4 && !isInDataBase" @click="newFileBank(0)">
                {{ $t("vue_label_file_new_lib") }}
              </div>
              <!--              新建文件夹 label.file.folder.create-->
              <div
                v-if="fileCur == 4 && isInDataBase && !readOnly"
                @click="newFileBank(1)"
              >
                {{ $t("label.file.folder.create") }}
              </div>
              <!--              添加文件 label.file.library.selectFile2Lib-->
              <div
                v-if="fileCur == 4 && isInDataBase && !readOnly"
                @click="addFiletoLib"
              >
                {{ $t("label.file.library.selectFile2Lib") }}
              </div>
              <!--              <div v-if="fileCur == 4&&isInDataBase">管理成员</div>-->
            </div>
          </div>
        </div>
        <div class="fileList_bottom">
          <div class="left_lists">
            <div class="all_files">
              <div class="all_data_files">
                <div
                  v-for="(item, index) in asideList"
                  :key="item.labelIndex"
                  @click="clickLabel(item.labelName, item.labelIndex)"
                  :class="{ active: index == fileCur }"
                >
                  {{ item.labelName }}
                </div>
              </div>
            </div>
          </div>
          <div class="right_table" v-if="fileCur !== 4">
            <el-table
              :data="fileListData"
              style="width: 100%"
              v-loading="loading"
              @sort-change="sortByWhat"
            >
              <div slot="empty" style="height: 200px; line-height: 200px">
                <i class="el-icon-warning-outline"></i
                >{{ $t("label.dashboard.noRecord") }}
              </div>
              <!--              文件名 label.addattachment.name-->
              <el-table-column
                prop="name"
                :label="$t('label.addattachment.name')"
                sortable="custom"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div
                    class="word_style overflow_style"
                    v-if="scope.row.type !== 'lib'"
                    @click="openPreview(scope.row)"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 25px; height: 25px; margin-right: 5px"
                    >
                      <use :href="allIcon[scope.row.type] || defaultIcon"></use>
                    </svg>
                    {{ scope.row.name }}
                  </div>
                  <div
                    class="word_style overflow_style"
                    v-else
                    @click="goFileData(scope.row)"
                  >
                    <!-- <img style="width: 25px;height: 25px;margin-right: 5px;"
                         :src="allIcon[scope.row.type]||defaultIcon"
                         alt=""> -->
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 25px; height: 25px; margin-right: 5px"
                    >
                      <use :href="allIcon[scope.row.type] || defaultIcon"></use>
                    </svg>
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>
              <!--              所有人 label.owner-->
              <el-table-column
                prop="ownername"
                :label="$t('label.owner')"
                width="200"
              ></el-table-column>
              <!--              上次修改日期 label.file.lastmodifydate-->
              <el-table-column
                prop="lastmodifydate"
                :label="$t('label.file.lastmodifydate')"
                width="200"
                sortable="custom"
              >
                <template slot-scope="scope">
                  {{ scope.row.lastmodifydate | datetimeFormat(countryCode) }}
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column
                label=""
                align="center"
                style="text-align: center"
                width="80"
              >
                <template slot-scope="scope">
                  <el-popover placement="top-start" trigger="hover">
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 20px; height: 20px; cursor: pointer"
                      slot="reference"
                    >
                      <use href="#icon-xiala"></use>
                    </svg>
                    <ul class="more-btn-ul">
                      <li
                        v-for="(btnItem, btnIndex) in scope.row.button"
                        :key="btnIndex"
                      >
                        <el-button
                          type="text"
                          size="mini"
                          class="more-btn-li"
                          v-show="
                            btnItem !== 'openin' && btnItem !== 'publicLink'
                          "
                          @click="dealWithOption(btnItem, scope.row)"
                        >
                          {{ btnConfig[btnItem] }}
                        </el-button>
                      </li>
                    </ul>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
            <div class="bottom_page">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :page-sizes="[10, 30, 50]"
                :page-size="page.pageSize"
                layout="sizes, prev, next"
                :total="page.total"
              ></el-pagination>
              <span style="margin-left: 10px">
                {{ $t("label.sales.dashboard.number") }} {{ page.pageNum }}
                {{ $t("label.tabpage.page") }}
              </span>
              <span class="go_homepage" @click="goHomePage">{{
                $t("label.homepage")
              }}</span>
            </div>
          </div>
          <div class="right_table" v-if="fileCur === 4">
            <el-table
              class="tableTop"
              :data="fileListData"
              style="width: 100%"
              v-loading="loading"
              @sort-change="libOrderFunc"
            >
              <div slot="empty" style="height: 200px; line-height: 200px">
                <i class="el-icon-warning-outline"></i
                >{{ $t("label.dashboard.noRecord") }}
              </div>
              <el-table-column
                prop="name"
                :label="$t('label.addattachment.name')"
                sortable="custom"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div
                    class="word_style overflow_style"
                    v-if="
                      scope.row.type !== 'lib' && scope.row.type !== 'folder'
                    "
                    @click="openPreview(scope.row)"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 25px; height: 25px; margin-right: 5px"
                    >
                      <use
                        :href="allIcon[scope.row.fileType] || defaultIcon"
                      ></use>
                    </svg>
                    {{ scope.row.name }}
                  </div>
                  <div
                    class="word_style overflow_style"
                    v-else
                    @click="goFileData(scope.row)"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 25px; height: 25px; margin-right: 5px"
                    >
                      <use :href="allIcon[scope.row.type] || defaultIcon"></use>
                    </svg>
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="ownername"
                :label="$t('label.owner')"
                width="200"
              ></el-table-column>
              <el-table-column
                prop="lastmodifydate"
                :label="$t('label.file.lastmodifydate')"
                width="200"
                sortable="custom"
              >
                <template slot-scope="scope">
                  {{ scope.row.lastmodifydate | datetimeFormat(countryCode) }}
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column
                label=""
                align="center"
                style="text-align: center"
                width="80"
              >
                <template slot-scope="scope">
                  <el-popover
                    placement="top-start"
                    v-show="scope.row.button.length > 0"
                    trigger="hover"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 20px; height: 20px; cursor: pointer"
                      slot="reference"
                    >
                      <use href="#icon-xiala"></use>
                    </svg>
                    <ul class="more-btn-ul">
                      <li
                        v-for="(btnItem, btnIndex) in scope.row.button"
                        :key="btnIndex"
                      >
                        <el-button
                          type="text"
                          size="mini"
                          v-if="
                            btnItem !== 'openin' && btnItem !== 'publicLink'
                          "
                          class="more-btn-li"
                          @click="dealWithOption(btnItem, scope.row)"
                          :key="btnIndex"
                        >
                          {{ btnConfig[btnItem] }}
                        </el-button>
                      </li>
                    </ul>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 上传新文件 label.file.uploadfiles-->
    <el-dialog
      :title="$t('label.file.uploadfiles')"
      width="35%"
      top="12%"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="addAppendixDialog"
      :before-close="beforeClose"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="action"
        :limit="10"
        :multiple="true"
        :on-exceed="handleExceed"
        :http-request="beforeUpload"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
      >
        <!--        选择文件 label.selectfile-->
        <el-button slot="trigger" size="small" type="primary">{{
          $t("label.selectfile")
        }}</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveFile" size="mini">{{
          $t("label.confirm")
        }}</el-button>
      </span>

      <!-- 上传进度条 -->
      <el-progress
        v-show="percentage > 0 && percentage < 100"
        class="progreccBox"
        color="#409eff"
        :percentage="percentage"
      ></el-progress>
    </el-dialog>
    <!--    新建文件库、新建文件夹 label.file.folder.create-->
    <el-dialog
      :title="
        dialogNewIndex === 0
          ? $t('vue_label_file_new_lib')
          : $t('label.file.folder.create')
      "
      :visible.sync="dialogNew"
      :before-close="handleClose"
      width="40%"
    >
      <div class="new-file-name">
        <span>
          <!--  文件库名称 -->
          <span v-show="dialogNewIndex === 0" style="color: #ff0000">*</span>
          {{
            dialogNewIndex === 0
              ? $t("vue_label_file_lib_name")
              : $t("label.file.foldername")
          }}
        </span>
        <el-input
          :placeholder="$t('lable.product.please.enter')"
          v-model="dialogNewInput"
          clearable
        >
        </el-input>
      </div>
      <div class="new-file-Des" v-show="dialogNewIndex === 0">
        <span>{{ $t("label.file.des") }}</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
          :placeholder="$t('label_tabpage_contentz')"
          v-model="dialogNewText"
          clearable
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{ $t("label.cancel") }}</el-button>
        <el-button type="primary" @click="comfirmNewLib">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!--编辑文件库、文件夹label_file_folder_edit-->
    <el-dialog
      :title="
        dialogEditIndex === 0
          ? $t('vue_label_file_edit_lib')
          : $t('label.file.folder.edit')
      "
      :visible.sync="dialogEdit"
      width="40%"
    >
      <div class="new-file-name">
        <span>
          <!--文件库名称-->
          <span v-show="dialogEditIndex === 0" style="color: #ff0000">*</span>
          {{
            dialogEditIndex === 0
              ? $t("vue_label_file_lib_name")
              : $t("label.file.foldername")
          }}
        </span>
        <!--请输入-->
        <el-input
          :placeholder="$t('lable.product.please.enter')"
          v-model="dialogEditInput"
          clearable
        >
        </el-input>
      </div>
      <div class="new-file-Des" v-show="dialogEditIndex === 0">
        <!--        描述-->
        <span>{{ $t("label.file.des") }}</span>
        <!--        请输入内容 label_tabpage_contentz-->
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
          :placeholder="$t('label_tabpage_contentz')"
          v-model="dialogEditText"
          clearable
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEdit = false">{{
          $t("label.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveEditLib">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      :title="$t('label.file.delete.info')"
      :visible.sync="dialogDelete"
      width="30%"
    >
      <!--      确认删除-->
      <span
        >{{ $t("label.sure.delete") }}
        <span style="color: dodgerblue"
          >{{ currentItem.name + "." + currentItem.type }}？</span
        ></span
      >
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="dialogDelete = false" :loading="deleteLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button
          type="primary"
          @click="confirmDelete"
          :loading="deleteLoading"
          >{{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--    删除文件库 label.emailmerge.list.deletefolder-->
    <el-dialog
      :title="$t('label.emailmerge.list.deletefolder')"
      :visible.sync="dialogDeleteLib"
      width="30%"
    >
      <!--      确认删除-->
      <span
        >{{ $t("label.sure.delete") }}
        <span style="color: dodgerblue">{{ currentItem.name }}？</span></span
      >
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="dialogDeleteLib = false" :loading="deleteLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button
          type="primary"
          @click="confirmDeleteLib"
          :loading="deleteLoading"
          >{{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!--    编辑文件-->
    <el-dialog title="" :visible.sync="dialogFileEdit" width="30%">
      <div slot="title" class="header-title">
        <!--        编辑-->
        <span>{{ $t("label.modify") + currentItem.name }}</span>
      </div>
      <div class="dialog-file-edit-item">
        <!--        文件名-->
        <div>
          <span style="color: red">*</span>{{ $t("label.addattachment.name") }}
        </div>
        <el-input v-model="editFileDetail.name"></el-input>
      </div>
      <div class="dialog-file-edit-item">
        <!--        描述-->
        <div>{{ $t("label.file.des") }}</div>
        <el-input v-model="editFileDetail.description"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="dialogFileEdit = false" :loading="editLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--        保存-->
        <el-button
          type="primary"
          @click="confirmFileEdit"
          :loading="editLoading"
          >{{ $t("label.save") }}</el-button
        >
      </span>
    </el-dialog>
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      :fromList="true"
      @closePreview="closePreview"
      @handleFile="handleFileClick"
      ref="previewFile"
      @editSaveSuccess="editSaveSuccess"
      @deleteSuccess="deleteSuccess"
    >
    </file-preview>
    <public-link ref="publicLinkFile"></public-link>
    <file-share-role ref="isShare"></file-share-role>
    <management-lib ref="managementRole"></management-lib>
    <add-files
      ref="atoLib"
      :isFromFileCenter="true"
      :dialogAddVisible="dialogAdd2Lib"
      @addSubmit="addLibSubmit"
      @close="closeAddFiletoLib"
    >
    </add-files>
  </div>
</template>

<script>
import * as fileApi from "./api.js";
import filePreview from "@/components/FileView/filePreview";
import AddFiles from "@/components/AddFiles/AddFiles";
import PublicLink from "./components/PublicLink";
import FileShareRole from "./components/FileShareRole";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import ManagementLib from "@/views/ccFileCenter/components/ManagementLib";
import * as Time from "@/utils/time";

export default {
  components: {
    filePreview,
    PublicLink,
    AddFiles,
    FileShareRole,
    ManagementLib,
  },
  data() {
    return {
      fileList: [],
      uploadUrl: "/test",
      libOrder: "", // 降序，升序排列
      libOrderBy: "", // 根据什么排序
      dialogAdd2Lib: false,
      readOnly: false,
      shareData: [],
      libLength: 0,
      editLoading: false, // 编辑
      deleteLoading: false, // 编辑
      dialogFileEdit: false, // 编辑文件
      currentFileName: "", // 当前选中的文件名称
      currentFileDes: "", // 当前选中的文件描述
      currentItem: {}, // 当前选中的文件
      editFileDetail: {}, // 编辑信息回显
      // 上传相关
      addAppendixDialog: false,
      action: "/test",
      fileType: [],
      size: 1024 * 500,
      multiple: true,
      // 按钮相关
      btnConfig: {
        download: this.$i18n.t("chatter.down"),
        publicLink: this.$i18n.t("label.file.publiclink"),
        viewinfo: this.$i18n.t("label.more.info"),
        share: this.$i18n.t("label.file.share"),
        delete: this.$i18n.t("label.delete"),
        editinfo: this.$i18n.t("label.modify"),
        renameFolder: this.$i18n.t("label.rename"),
        editlib: this.$i18n.t("vue_label_file_edit_lib"),
        managelib: this.$i18n.t("label.file.library.managemembers1"),
        deletelib: this.$i18n.t("vue_label_file_delete_lib"),
        deleteFolder: this.$i18n.t("label.emailmerge.list.deletefolder"),
      },
      dialogDelete: false, // 删除
      dialogDeleteLib: false, // 删除
      showPreview: false, // 预览
      showPreviewData: {}, // 预览数据
      dialogNew: false, // 新建文件夹
      dialogNewIndex: -1, // 1文件夹  0文件库
      dialogNewInput: "", // 名称
      dialogNewText: "", // 文本域
      dialogEdit: false, // 编辑文件夹
      dialogEditIndex: -1, // 1文件夹  0文件库
      dialogEditInput: "", // 名称
      dialogEditText: "", // 文本域
      dialogMove: false, // 移动文件弹窗
      tabNum: 0, // 文件库tab索引
      tabArr: [{ idx: 0, name: this.$i18n.t("vue_label_file_libraray") }], // 记录文件库tab索引
      currentLib: { idx: 0, name: this.$i18n.t("vue_label_file_libraray") }, // 当前选中文件库/文件夹
      curLib: { idx: 0, name: this.$i18n.t("vue_label_file_libraray") }, // 当前选中文件库
      loading: false,
      isInDataBase: false, // 是否在文件库内
      // 侧边切换选项
      asideList: [
        { labelName: this.$i18n.t("vue_label_file_my_file"), labelIndex: 0 },
        { labelName: this.$i18n.t("label.file.sharedwithme"), labelIndex: 1 }, // 共享给我的 label.file.sharedwithme
        { labelName: this.$i18n.t("label.report.recently"), labelIndex: 2 }, // 最近查看
        { labelName: this.$i18n.t("label.chatter.following"), labelIndex: 3 }, // 正在关注
        { labelName: this.$i18n.t("vue_label_file_libraray"), labelIndex: 4 },
      ],
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      searchInput: "", // 输入框的值
      fileCur: 2, // 当前选项    默认展示最近查看
      labelTitle: this.$i18n.t("vue_label_file_my_file"), // 当前选项名称
      listData: [], // 未处理的数据
      fileListData: [], // 分页后的数据
      allFileListData: [], // 展示的数据
      showType: "recent",
      // 排序
      orderBy: "",
      sortBy: "",
      // 页码相关
      pageNumTotal: 3,
      jumpNum: 1,
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      percentage: 0, // 上传文件进度
      // 上传文件数据量
      uploadNum: 0,
    };
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("FL"));
    this.getList();
  },
  methods: {
    /**
     * 
     * @param {type} 参数
     * @returns {type} 返回值
     */
    beforeClose(done){
      this.uploadNum = 0
      done()
    },
    /**
     * createQueryLibs：创建文档库
     * saveFolderLibs：保存文件夹
     */
    comfirmNewLib() {
      if (this.dialogNewInput.replace(/\s+/g, "") === "") {
        // 请填写正确的名称
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_file_input_correct_name"),
        });
        return false;
      }
      if (this.dialogNewIndex < 1) {
        fileApi
          .createQueryLibs({
            name: this.dialogNewInput,
            description: this.dialogNewText,
          })
          .then(() => {
            this.dialogNew = false;
            this.dialogNewInput = "";
            this.dialogNewText = "";
            this.getLibList();
          });
      } else {
        fileApi
          .saveFolderLibs({
            name: this.dialogNewInput,
            parentId: this.currentLib.id, //父级id
            parentLibId: this.curLib.id, //父级文件库id
          })
          .then(() => {
            this.dialogNew = false;
            this.dialogNewInput = "";
            this.getLibList(this.currentLib.id);
          });
      }
    },
    /**
     * 切换排序按钮
     * 升序，降序
     */
    sortByWhat(column) {
      switch (column.order) {
        case "descending":
          this.sortBy = "desc";
          break;
        case "ascending":
          this.sortBy = "asc";
          break;
        case null:
          this.sortBy = "";
          break;
      }
      this.orderBy = column.prop;
      //this.sortBy = ""为取消排序，默认走时间排序
      if (this.sortBy) {
        this.getList(this.searchInput, this.orderBy, this.sortBy);
      } else {
        this.getList();
      }
    },
    /**
     * 切换排序按钮
     * 升序，降序
     */
    libOrderFunc(column) {
      switch (column.order) {
        case "descending":
          this.libOrder = "desc";
          break;
        case "ascending":
          this.libOrder = "asc";
          break;
        case null:
          this.libOrder = "";
          break;
      }
      this.libOrderBy = column.prop;
      this.getLibList(this.currentLib.id);
    },
    handleRemove() {
      this.percentage = 0;
    },
    // 文件上传前执行的事件
    beforeUpload(file) {
      file = file.file
      let size = file.size / 1024;
      if (size > this.size) {
        // 文件大小超过限制
        this.$message.error(
          file.name + this.$i18n.t("vue_label_file_size_limit_tip")
        );
        return false;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("isFromEmail", "");
      /*
       * 获取上传文件时的进度
       * 1.进度条的实现是依靠axios中提供的onUploadProgress函数
       * 2.该函数提供了文件已上传部分的大小progressEvent.loaded、文件总大小progressEvent.total
       * 3.利用这两个数据就可以计算出已经上传文件的进度
       */
      let config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };
      this.uploadNum++
      fileApi
        .upload(formData, config)
        .then((res) => {
          this.uploadNum--
          if (res.result) {
            // 上传成功 label.uploadsuc
            this.$message.success(this.$i18n.t("label.uploadsuc"));
            this.getList();
          } else {
            // 上传失败 label.file.upload.fail
            this.$message.warning(this.$i18n.t("label.file.upload.fail"));
          }
          if(!this.uploadNum){
            // 关闭弹窗
            this.addAppendixDialog = false;
            this.percentage = 0;
          }
        })
        .catch(() => {
          this.uploadNum--
          return false;
        });
    },
    // 编辑成功
    editSaveSuccess() {
      this.getList(this.searchInput, this.orderBy, this.sortBy);
    },
    // 删除成功
    deleteSuccess() {
      this.getList(this.searchInput, this.orderBy, this.sortBy);
    },
    handleExceed() {
      // 文件个数超出预设，如果需要替换文件，请先删除已上传的文件
      this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
    },
    // 上传文件弹框-点击保存按钮
    saveFile() {
      this.$refs.upload?.submit()
      // this.addAppendixDialog = false;
      // this.percentage = 0;
    },
    handleShare(obj) {
      if (obj.file_info_id) {
        this.$refs.isShare.open(obj.file_info_id, obj.name);
      } else {
        this.$refs.isShare.open(obj.id, obj.name);
      }
    },
    // 点击按钮
    dealWithOption(item, obj) {
      this.currentItem = obj;
      switch (item) {
        // 下载
        case "download":
          this.downloadFile(obj);
          break;
        // 公共链接
        case "publicLink":
          this.$refs.publicLinkFile.openPage(
            this.currentItem,
            this.currentItem.file_info_id || this.currentItem.id
          );
          break;
        // 查看详情
        case "viewinfo":
          this.goFileDetail(obj);
          break;
        // 分享
        case "share":
          this.handleShare(obj);
          break;
        // 打开
        case "openin":
          this.openPreview(obj);
          break;
        // 删除
        case "delete":
          this.deleteItem(obj);
          break;
        // 编辑信息
        case "editinfo":
          this.editInfo(obj);
          break;
        // 重命名文件夹
        case "renameFolder":
          this.dialogEdit = true;
          this.dialogEditIndex = 1;
          this.dialogEditInput = this.currentItem.name;
          break;
        // 编辑文件库
        case "editlib":
          this.openEditLib(obj);
          break;
        // 管理成员
        case "managelib":
          this.manageLib(obj);
          break;
        // 删除文件库
        case "deletelib":
          this.deleteItemLib(obj);
          break;
        // 删除文件夹
        case "deleteFolder":
          this.deleteItemLib(obj);
          break;
        // 移动
        case "move":
          this.dialogMove = true;
          break;
      }
    },
    // 下载
    downloadFile(item) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
        item.file_info_id || item.id
      }`;
      link.click();
    },
    // 编辑信息
    editInfo(item) {
      this.loading = true;
      fileApi
        .editFile({
          id: item.file_info_id || item.id,
        })
        .then((res) => {
          this.loading = false;
          this.editFileDetail = res.data;
          this.dialogFileEdit = true;
        });
    },
    confirmFileEdit() {
      if (this.editFileDetail.name.replace(/\s+/g, "") !== "") {
        this.editLoading = true;
        fileApi
          .saveEditFile({
            id: this.currentItem.file_info_id || this.currentItem.id,
            name: this.editFileDetail.name,
            description: this.editFileDetail.description || "",
          })
          .then(() => {
            if (this.currentItem.file_info_id) {
              this.getLibList(this.currentLib.id);
            } else {
              this.getList(this.searchInput, this.orderBy, this.sortBy);
            }
            this.dialogFileEdit = false;
            // 保存成功 savesuccess
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("savesuccess"),
            });
            this.editLoading = false;
          });
      } else {
        // 请填写文件名 label.weixin.input.filename
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.input.filename"),
        });
      }
    },
    // 管理文件库成员
    manageLib(obj) {
      this.$refs.managementRole.open(obj.id);
    },
    // 文件库-打开弹窗
    openEditLib(obj) {
      this.dialogEdit = true;
      this.dialogEditIndex = 0;
      fileApi.getEditQueryLibs({ id: obj.id }).then((res) => {
        this.dialogEditInput = res.data.name;
        this.dialogEditText = res.data.description;
      });
    },
    // 文件库-保存文本库
    saveEditLib() {
      if (this.dialogEditInput.replace(/\s+/g, "") === "") {
        // 请填写正确的名称
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_file_input_correct_name"),
        });
        return false;
      }
      if (this.dialogEditIndex < 1) {
        fileApi
          .saveEditQueryLibs({
            id: this.currentItem.id,
            name: this.dialogEditInput,
            description: this.dialogEditText,
          })
          .then(() => {
            this.dialogEdit = false;
            this.getLibList();
          });
      } else {
        fileApi
          .saveFolderLibs({
            name: this.dialogEditInput,
            id: this.currentItem.id,
            parentId: this.currentLib.id, //父级id
            parentLibId: this.curLib.id, //父级文件库id
          })
          .then(() => {
            this.dialogEdit = false;
            this.dialogEditInput = "";
            this.getLibList(this.currentLib.id);
          });
      }
    },
    // 删除文件
    deleteItem() {
      this.dialogDelete = true;
    },
    /**
     * 删除文件
     */
    confirmDelete() {
      this.deleteLoading = true;
      fileApi
        .deleteFile({
          id: this.currentItem.file_info_id || this.currentItem.id,
        })
        .then(() => {
          if (this.currentItem.file_info_id) {
            this.getLibList(this.currentLib.id);
          } else {
            this.getList(this.searchInput, this.orderBy, this.sortBy);
            this.page.pageNum = 1;
            this.jumpNum = this.page.pageNum;
          }
          this.dialogDelete = false;
          this.deleteLoading = false;
          // 文件删除成功 label.file.delete.success
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.file.delete.success"),
          });
        });
    },
    deleteItemLib() {
      this.dialogDeleteLib = true;
    },

    /**
     * deleteQueryLibs:删除文档库
     */
    confirmDeleteLib() {
      this.deleteLoading = true;
      fileApi
        .deleteQueryLibs({
          id: this.currentItem.id,
        })
        .then(() => {
          this.getLibList(this.currentLib.id);
          this.dialogDeleteLib = false;
          this.deleteLoading = false;
          // 删除成功 label_tabpage_delsuccessz
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label_tabpage_delsuccessz"),
          });
        });
    },
    // 新建
    newFileBank(flag) {
      this.dialogNew = true;
      this.dialogNewIndex = flag;
    },
    // 添加文件至文件库
    addFiletoLib() {
      this.$refs.atoLib.initPage();
      this.dialogAdd2Lib = true;
    },
    // 添加文件至文件库
    closeAddFiletoLib() {
      this.dialogAdd2Lib = false;
    },
    // 添加文件至文件库
    addLibSubmit(arr) {
      let ids = "";
      let repeatedArr = [];
      arr.map((item, index) => {
        ids += item.id;
        if (index !== arr.length - 1) {
          ids += ",";
        }
      });
      this.fileListData.forEach((item) => {
        arr.forEach((itemBase) => {
          if (item.file_info_id === itemBase.id) {
            repeatedArr.push(itemBase);
          }
        });
      });
      fileApi
        .addFileToLibs({
          fileIds: ids,
          parentId: this.currentLib.id, //父级id
          libraryid: this.currentLib.id, //父级文件库id
        })
        .then(() => {
          if (repeatedArr.length > 0) {
            let str = "";
            repeatedArr.forEach((item) => {
              str += item.name + "." + item.type + " ";
            });
            // 已存在于此文件库
            this.$message.warning(
              str + "--" + this.$i18n.t("vue_label_file_already_in_lib")
            );
          } else {
            // 添加成功
            this.$message.success(this.$i18n.t("vue_label_notice_add_success"));
          }
          this.dialogAdd2Lib = false;
          this.getLibList(this.currentLib.id);
        });
    },
    // 上传文件按钮
    handleUpload() {
      fileApi.queryLimit({ type: "file" }).then((res) => {
        this.size = Number(res.data.slice(0, res.data.length - 2)) * 1024;
        this.addAppendixDialog = true;
        this.percentage = 0;
        this.$refs.upload && this.$refs.upload.clearFiles();
      });
    },
    handleClose() {
      this.outerVisible = false;
      this.dialogNewInput = "";
      this.dialogNewText = "";
      this.dialogNew = false;
    },
    // 获取列表
    getList(keyword, prop, sort) {
      this.loading = true;
      const { pageNum, pageSize } = this.page;
      if (this.showType) {
        let op = {
          type: this.showType,
          searchWord: keyword,
          orderByField: prop,
          orderType: sort,
          page: pageNum,
          pageSize: pageSize,
        };
        fileApi.QueryListTab(op).then((res) => {
          this.listData = res.data.listFile || [];
          this.fileListData = res.data.listFile;
          this.jumpNum = this.page.pageNum;
          this.closePreview();
          this.loading = false;
          if (res.data.isMore === true) {
            this.page.total =
              (this.page.pageNum + 1) * res.data.listFile.length;
          } else if (!res.data.isMore && this.page.pageNum == 1) {
            this.page.total = res.data.listFile.length;
          }
        });
      } else {
        this.currentLib = {};
        this.curLib = {};
        this.getLibList();
      }
    },
    // 获取文件库
    getLibList(libid) {
      this.loading = true;
      if (libid) {
        this.isInDataBase = true;
      } else {
        this.isInDataBase = false;
      }
      let op = {
        libraryId: libid,
        orderByField: this.libOrderBy,
        order: this.libOrder,
      };
      fileApi.getQueryLibs(op).then((res) => {
        this.fileListData = res.data.list;
        this.libLength = res.data.list.length;
        if (res.data.canCreateFile === "0") {
          this.readOnly = true;
        } else if (res.data.canCreateFile === "1") {
          this.readOnly = false;
        }
        this.closePreview();
        this.loading = false;
      });
    },
    // 数组分页
    reSetData(data, num) {
      let arr = [];
      let len = data.length;
      for (let i = 0; i < len; i += num) {
        arr.push(data.slice(i, i + num));
      }
      return arr;
    },
    // 切换条数时，判断当前是否进行排序，如果排序的话就传参
    handleSizeChange(val) {
      // this.loading = true;
      this.page.pageSize = val;
      if (this.sortBy) {
        this.getList(this.searchInput, this.orderBy, this.sortBy);
      } else {
        this.getList();
      }
      // this.allFileListData = this.reSetData(this.listData, val)
      // this.page.pageNum = 1
      // this.jumpNum = this.page.pageNum;
      // this.fileListData = this.allFileListData[0]
      // this.loading = false;
    },
    /**
     * 切换页数
     */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      // this.fileListData = this.allFileListData[val - 1]
      //判断当前是否进行排序，如果排序的话就传参
      if (this.sortBy) {
        this.getList(this.searchInput, this.orderBy, this.sortBy);
      } else {
        this.getList();
      }
    },
    //页码回到首页
    goHomePage() {
      this.page.pageNum = 1;
      //判断当前是否进行排序，如果排序的话就传参
      if (this.sortBy) {
        this.getList(this.searchInput, this.orderBy, this.sortBy);
      } else {
        this.getList();
      }
    },
    // 文件详情
    goFileDetail(item) {
      this.closePreview();
      this.$router.push({
        path: "/ccFileCenter/fileDetail",
        query: {
          id: item.file_info_id || item.id,
        },
      });
    },
    // 文件库
    goFileData(item) {
      // 这块要记录每个文件夹的请求入参，以便切换返回
      this.isInDataBase = true;
      this.getLibList(item.id);
      this.tabNum++;
      this.tabArr.push({
        idx: this.tabNum,
        name: item.name,
        id: item.id,
      });
      this.currentLib = {
        idx: this.tabNum,
        name: item.name,
        id: item.id,
      };
      this.tabArr.map((i) => {
        if (i.idx === 1) {
          this.curLib = i;
        }
      });
      this.labelTitle = item.name;
    },
    jumpTab(flag) {
      if (flag.idx == this.tabNum) {
        return false;
      }
      if (flag.idx == 0) {
        this.tabNum = 0;
        this.tabArr = [
          { idx: 0, name: this.$i18n.t("vue_label_file_libraray") },
        ];
        this.isInDataBase = false;
        this.getLibList();
        this.labelTitle = this.$i18n.t("vue_label_file_libraray");
      } else {
        this.tabNum = flag.idx;
        this.tabArr.length = flag.idx + 1;
        this.getLibList(flag.id);
        this.labelTitle = flag.name;
        this.currentLib = {
          idx: flag.idx,
          name: flag.name,
          id: flag.id,
        };
      }
    },
    //点击侧边文件列表
    async clickLabel(item, index) {
      this.fileCur = index;
      this.labelTitle = item;
      this.searchInput = "";
      // ownedbyme:我的文件
      // sharedwithme:与我共享
      // recent:最近
      // following:追随
      switch (index) {
        case 0:
          this.showType = "ownedbyme";
          break;
        case 1:
          this.showType = "sharedwithme";
          break;
        case 2:
          this.showType = "recent";
          break;
        case 3:
          this.showType = "following";
          break;
        case 4:
          this.showType = "";
          this.tabNum = 0;
          this.tabArr = [
            { idx: 0, name: this.$i18n.t("vue_label_file_libraray") },
          ];
          this.isInDataBase = false;
          this.labelTitle = this.$i18n.t("vue_label_file_libraray");
          break;
      }
      this.page.pageNum = 1;
      this.getList();
    },
    // 顶部搜索
    fileSelectSearch() {
      this.getList(this.searchInput);
    },
    // 列表打开预览
    async openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = item;
      this.currentItem = item;
      // 传入按钮如果不需要按钮，可以传空数组
      this.$refs.previewFile.handleBtn(item.button);
      // let { data } = fileApi.preview({
      //   id: item.file_info_id || item.id,
      // });
      // if (data?.file_content_id) {
      // } 
    },
    // 关闭列表预览
    closePreview() {
      // 列表打开预览
      this.showPreview = false;
    },
    handleFileClick(item) {
      this.dealWithOption(item, this.currentItem);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/table.scss";
// 调整进度条位置
.progreccBox {
  ::v-deep .el-progress {
    top: -4px;
  }
}
.body {
  padding: 10px;
  height: 100%;
}

.fileList {
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  .fileList_content {
    height: 100%;
    .fileList_top {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f8f8f8;
      border-bottom: 1px solid #dddbda;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .top_tltle {
        width: 50%;
        color: #222222;

        div:nth-child(1),
        div:nth-child(3) {
          font-size: 12px;
        }

        div:nth-child(2) {
          font-size: 16px;
          font-weight: bold;
        }

        .file-dataBase {
          display: flex;
          align-items: center;
          cursor: pointer;
          flex-wrap: wrap;

          span {
          }

          span:hover {
            color: dodgerblue;
          }
        }
      }

      .right_infos {
        display: flex;

        .top_search {
          margin-right: 10px;

          ::v-deep .el-input__inner {
            width: 266px;
            height: 30px;
            padding: 0 15px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 0 3px 3px 0;
          }

          ::v-deep .el-input__icon {
            line-height: 30px;
          }

          ::v-deep .el-input__suffix {
            left: 0;
            text-align: right;
          }
        }

        .top_butts {
          display: flex;
          height: 30px;

          div {
            background: #006dcc;
            color: #fff;
            font-size: 12px;
            border-radius: 4px;
            padding: 5px 15px;
            cursor: pointer;
            margin-right: 10px;
          }

          div:last-child {
            margin-right: 0;
          }
        }

        .set_img {
          margin-left: 11px;
          border: 1px solid #dddbda;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .active_img {
          border: 1px solid #e89335;
        }

        .set_bounce {
          position: absolute;
          right: 0;
          top: 30px;
          z-index: 100;
          width: 240px;
          height: 340px;
          background: #ffffff;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);

          .top_title {
            padding: 12px 10px;
            font-size: 12px;
            color: #333333;
            font-weight: bold;
            border-bottom: 1px solid #dcdcdc;
            text-align: left;
          }

          .all_fields {
            height: 240px;
            overflow-y: auto;
            font-size: 12px;
            color: #333333;

            .fixed_field {
              padding: 12px 10px;
              border-bottom: 1px solid #dcdcdc;
              display: flex;
              justify-content: space-between;

              img {
                width: 12.4px;
                height: 14px;
                margin-left: 17px;
              }

              ::v-deep .el-checkbox {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-direction: row-reverse;
              }
            }

            ::v-deep .el-checkbox-group {
              display: flex;
              flex-direction: column;

              .el-checkbox {
                padding: 12px 38px 12px 10px;
                border-bottom: 1px solid #dcdcdc;
                margin-right: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
              }
            }

            ::v-deep .el-checkbox__label {
              font-size: 12px;
            }

            ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
              background: #1b5297;
            }

            ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #333333;
            }
          }

          .bottm_butts {
            height: 60px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            div {
              width: 54px;
              height: 30px;
              border-radius: 4px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            div:first-child {
              color: #5780b3;
              border: 1px solid #dcdcdc;
              background: #fff;
              margin-right: 5px;
            }

            div:last-child {
              background: #1b5297;
            }
          }
        }
      }
    }

    .fileList_bottom {
      height: calc(100% - 81px);
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .left_lists {
        background-color: #fff;
        border: 1px solid rgb(220, 220, 220);
        width: 240px;
        font-size: 14px;

        .all_files {
          .all_data_files {
            div {
              padding: 11px 20px 10px 17px;
              cursor: pointer;
            }
          }
        }

        .all_folders {
          height: calc(100% - 160px);

          .all_data_folders {
            height: calc(100% - 50px);
            overflow-y: auto;

            .top_folder_datas {
              border-bottom: 1px solid #eeeeee;
              padding: 10px 0;
            }

            .bottom_folder_datas {
              padding-top: 10px;
            }

            .each_folder {
              padding: 11px 20px 10px 17px;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;

              img {
                width: 16px;
                height: 16px;
              }

              .operation_img {
                display: flex;
              }
            }
          }
        }

        .title_div {
          background: #f3f2f2;
          padding: 11px 20px 10px 17px;
          font-weight: bold;
        }

        .placedTop {
          padding: 11px 20px 10px 17px;
          border-bottom: 1px solid #ccc;
        }
      }
      ::v-deep .el-table__body-wrapper {
        overflow-y: auto;
        height: calc(100% - 51px);
      }
      .right_table {
        width: calc(100% - 250px);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ::v-deep .el-table {
          // height: 450px;
          // height: 100vh;
          overflow-y: auto;

          .word_style {
            color: #006dcc;
            cursor: pointer;
          }

          .overflow_style {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
          }
        }

        .operation_butts {
          position: relative;
          cursor: pointer;

          img {
            width: 19px;
            height: 19px;
          }

          // ::v-deep .el-popover.myPopover .moreMenus .el-card__body li:hover{
          //   cursor: pointer !important;
          // }
        }

        .bottom_page {
          height: 66px;
          padding: 0 15px;
          background: #ffffff;
          box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);
          border-radius: 0 0 3px 3px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .page-option {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .page-option-l {
              display: flex;
              align-items: center;
            }
            .page-option-m {
              display: flex;
            }
            .page-option-r {
              display: flex;
              align-items: center;
              input {
                width: 26px;
                height: 20px;
                outline: none;
                border: 1px solid #ccc;
                padding-left: 6px;
              }
              button {
                border: 1px solid #ccc;
                background: none;
                font-size: 12px;
                text-align: center;
                outline: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .active {
      color: #006dcc;
    }
  }
}

.no-data {
  text-align: center;
  margin: 20px auto;
  color: #909399;
}

::v-deep .el-input .el-input--suffix {
  ::v-deep .el-input__inner {
    width: 112px !important;
    border-radius: 3px 0 0 3px !important;
    margin-top: -4px;
  }
}

::v-deep .el-input-group__prepend {
  left: 1px;
  top: 1px;
  border: 0px;
}
.el-popover.myPopover {
  padding: 0 !important;
  margin: 0 0 0 90px;
  min-width: 113px;

  .popper__arrow,
  .popper__arrow::after {
    display: none !important;
  }

  .moreMenus {
    width: 113px;

    .el-card__body {
      padding: 10px;

      ol,
      ul,
      dl {
        margin-bottom: 0;
      }

      li {
        height: 30px;
        line-height: 30px;

        &:hover {
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
  }

  .folder_operation {
    width: 120px;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .el-button {
      margin-left: 0;
      padding: 8px 10px;
      font-size: 12px;
      color: #080707;
      border: none;
      text-align: left;
    }

    .el-button.is-disabled {
      color: #c0c4cc;
      border-color: #ebeef5;
    }
  }
}

.new-file-name {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  > span {
    width: 30%;
  }
}

.new-file-Des {
  display: flex;
  align-items: flex-start;

  > span {
    width: 30%;
  }
}

.header-title {
  display: flex;
  align-items: center;
}

.dialog-file-edit-item {
  margin-bottom: 20px;
}
.more-btn-ul {
  margin: 0;
  .more-btn-li {
    color: #303133;
    cursor: pointer;
  }
  .more-btn-li:hover {
    color: #409eff;
  }
}
.go_homepage {
  margin-left: 20px;
  cursor: pointer;
  color: #006dcc;
}
.upload-demo {
  height: 70px;
}
</style>
