<template>
  <div>
    <el-dialog
      title=""
      width="50%"
      :visible.sync="dialogManagement"
      :before-close="closeLib"
    >
      <!--      管理文件库成员-->
      <div slot="title" class="dialog-title">
        {{ $t("vue_label_file_manage_lib_members") }}
      </div>
      <div class="management-content">
        <div class="management-content-top">
          <div class="sc-top-left">
            <!--            添加成员  label.file.library.managemembers.addmember-->
            <div class="sc-top-left-tip">
              {{ $t("label.file.library.managemembers.addmember") }}
            </div>
            <div style="display: flex">
              <div class="select-role-type">
                <el-select
                  style="width: 60px"
                  v-model="checkedType"
                  @change="handleChangeType"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <svg
                      style="height: 20px; width: 20px; margin-right: 10px"
                      aria-hidden="true"
                    >
                      <use
                        :href="
                          item.value === 'person'
                            ? '#icon-guanzhuzhe'
                            : '#icon-group'
                        "
                      ></use>
                    </svg>
                    <!--                    </span>-->
                    <span>{{ item.label + item.value }}</span>
                  </el-option>
                </el-select>
                <svg class="icon show-checked-type" aria-hidden="true">
                  <use
                    :href="
                      checkedType === 'person'
                        ? '#icon-guanzhuzhe'
                        : '#icon-group'
                    "
                  ></use>
                </svg>
              </div>
              <!--              请输入关键词 label.searchkey-->
              <el-select
                ref="selA"
                v-model="value"
                multiple
                filterable
                remote
                reserve-keyword
                :placeholder="$t('label.searchkey')"
                :remote-method="remoteMethod"
                @change="handleChangeList"
                @focus="handleFocusList"
                popper-class="select-down"
                :popper-append-to-body="false"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sc-top-right">
            <!--            权限 label.limit-->
            <div class="sc-top-right-tip">{{ $t("label.limit") }}</div>
            <el-select
              v-model="selectValue"
              :placeholder="$t('label.select.please')"
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="management-content-center">
          <!--          当前成员 label.file.library.managemembers.memberlist-->
          <div class="sc-center-banner">
            {{ $t("label.file.library.managemembers.memberlist") }}
          </div>
          <div class="sc-center-list" v-loading="tableLoading">
            <div
              class="sc-item"
              v-for="(item) in currentRoleList"
              :key="item.id"
            >
              <div class="sc-item-left">
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="
                      item.managertype === 'group'
                        ? allIcon['group']
                        : allIcon['followList']
                    "
                  ></use>
                </svg>
                <span class="sc-item-left-name">{{ item.name }}</span>
              </div>
              <div class="sc-item-right">
                <!--                所有人 label.file.owner-->
                <el-select
                  v-if="item.managerrole === 'owner'"
                  class="sc-item-right-select"
                  size="mini"
                  :disabled="item.managerrole === 'owner'"
                  :value="$t('label.file.owner')"
                >
                  <el-option
                    v-for="itemB in selectOptions"
                    :key="itemB.value"
                    :label="itemB.label"
                    :value="itemB.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  @change="changeRolePem(item)"
                  v-if="item.managerrole !== 'owner'"
                  class="sc-item-right-select"
                  size="mini"
                  v-model="item.managerrole"
                >
                  <el-option
                    v-for="itemB in selectOptions"
                    :key="itemB.value"
                    :label="itemB.label"
                    :value="itemB.value"
                  >
                  </el-option>
                </el-select>
                <span class="sc-item-right-close" @click="delSingleItem(item)">
                  <i
                    v-show="item.managerrole !== 'owner'"
                    class="el-icon-close"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="closeLib" v-loading="saveLoading">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button
          type="primary"
          @click="comfirmAddRole"
          v-loading="saveLoading"
          >{{ $t("label.confirm") }}</el-button
        >
      </div>
    </el-dialog>
    <!--    确认删除  label.sure.delete-->
    <el-dialog
      :title="$t('label.sure.delete')"
      :visible.sync="dialogDelete"
      width="30%"
    >
      <div style="padding: 36px">
        <!--        删除文件库成员-->
        <span>{{
          $t("vue_label_file_delete_lib_member") + deleteItem.name
        }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="dialogDelete = false">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button type="primary" @click="comfirmDeleteItem">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as fileApi from "../api.js";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";

export default {
  name: "ManagementLib",
  data() {
    return {
      libId: "",
      options: [],
      value: [],
      tableLoading: false,
      saveLoading: false,
      loading: false,
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      dialogManagement: false,
      roleConfig: {
        owner: this.$i18n.t("label.file.owner"),
        check: this.$i18n.t("label.readonly"),
        cooperation: this.$i18n.t("label.readwrite"),
        manager: this.$i18n.t("chatter.group.manager"),
      },
      selectOptions: [
        { value: "check", label: this.$i18n.t("label.readonly") },
        { value: "cooperation", label: this.$i18n.t("label.readwrite") },
        { value: "manager", label: this.$i18n.t("chatter.group.manager") },
      ],
      typeOptions: [
        {
          value: "person",
          // 用户 label.user
          label: this.$i18n.t("label.user"),
        },
        {
          value: "group",
          // 小组
          label: this.$i18n.t("label.ccchat.group.section"),
        },
      ],
      selectValue: "check",
      checkedType: "person",
      currentRoleList: [],
      deleteItem: {},
      dialogDelete: false,
    };
  },
  methods: {
    closeLib() {
      this.dialogManagement = false;
      this.value = [];
      this.options = [];
      this.selectValue = "check";
      this.checkedType = "person";
    },
    handleChangeType() {
      this.options = [];
    },
    handleChangeList() {
      this.currentRoleList.forEach((item) => {
        this.value.forEach((itemValue, indexValue) => {
          if (item.managerid === itemValue) {
            this.value.splice(indexValue, 1);
            // 已经是管理成员，请不要重复添加 label.file.library.addmembprompt
            this.$message.warning(
              `${item.name + this.$i18n.t("label.file.library.addmembprompt")}`
            );
          }
        });
      });
    },
    // 获取焦点请求列表
    handleFocusList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let option = {
          fileId: this.libId,
          type: this.checkedType,
          keyword: this.searchQuery,
          op: "libmanager",
        };
        fileApi
          .getShareRoleList(option)
          .then((res) => {
            this.options = res.data;
          })
      }, 200);
    },
    // 打开搜索列表
    searchRolesList() {
      this.remoteMethod();
    },
    // 搜索列表
    remoteMethod(query) {
      this.searchQuery = query;
      let option = {
        fileId: this.libId,
        type: this.checkedType,
        keyword: this.searchQuery,
        op: "libmanager",
      };
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        fileApi
          .getShareRoleList(option)
          .then((res) => {
            this.options = res.data;
          })
      }, 200);
    },
    // 打开弹窗
    open(id) {
      this.libId = id;
      this.dialogManagement = true;
      this.getRoles();
    },
    // 获取已选列表
    getRoles() {
      this.tableLoading = true;
      fileApi.getLibManager({ id: this.libId }).then((res) => {
        this.currentRoleList = res.data;
        this.tableLoading = false;
      });
    },
    // 打开删除单个成员
    delSingleItem(obj) {
      this.dialogDelete = true;
      this.deleteItem = obj;
    },
    // 删除单个成员
    comfirmDeleteItem() {
      fileApi.delLibManager({ id: this.deleteItem.id }).then(() => {
        this.getRoles();
        this.dialogDelete = false;
      });
    },
    // 恒该单个成员权限
    changeRolePem(obj) {
      let ob = {
        id: obj.managerid,
        managerType: obj.managertype,
        managerRole: obj.managerrole,
      };
      let op = {
        id: this.libId,
        value: JSON.stringify([ob]),
      };
      fileApi.saveLibManager(op).then(() => {
        // 更改成功
        this.$message.success(this.$i18n.t("vue_label_notice_change_success"));
        this.getRoles();
      });
    },
    // 确认保存
    comfirmAddRole() {
      if (this.value.length === 0) {
        // 请至少选择一个成员 label.campaigns.memberalert
        this.$message.warning(this.$i18n.t("label.campaigns.memberalert"));
        return false;
      }
      let obList = [];
      this.saveLoading = true;
      this.value.map((item) => {
        if (item.substring(0, 3) === "005") {
          obList.push({
            id: item,
            managerType: "person",
            managerRole: this.selectValue,
          });
        } else {
          obList.push({
            id: item,
            managerType: "group",
            managerRole: this.selectValue,
          });
        }
      });
      let op = {
        id: this.libId,
        value: JSON.stringify(obList),
      };
      fileApi.saveLibManager(op).then(() => {
        this.getRoles();
        this.saveLoading = false;
        // 管理成员保存成功 label.file.library.successmanagemember
        this.$message.success(
          this.$i18n.t("label.file.library.successmanagemember")
        );
        this.closeLib();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tag.el-tag--info {
  display: flex;
  align-items: center;
}
::v-deep .el-select__tags-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
::v-deep .select-down {
  min-width: 100px !important;
  max-width: 200px !important;
}
::v-deep .select-down .el-select-dropdown__item {
  width: 200px !important;
}
::v-deep .el-input__suffix-inner .el-icon- {
  transform: rotate(0deg);
}
::v-deep .el-icon-:before {
  content: "\e778";
}
::v-deep .el-dialog__body {
  padding: 16px 0px;
  overflow: visible;
}
::v-deep .el-select {
  width: 100%;
}
.management-content {
  width: 100%;
  .management-content-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 36px;
    box-sizing: border-box;
    margin-bottom: 11px;
    .sc-top-left {
      width: 55%;
      .sc-top-left-tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
    .sc-top-right {
      width: 35%;
      .sc-top-right-tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }
  .management-content-center {
    width: 100%;
    .sc-center-banner {
      padding: 0 36px;
      box-sizing: border-box;
      background: #f3f2f2;
      height: 30px;
      line-height: 30px;
      width: 100%;
      margin-bottom: 20px;
    }
    .sc-center-list {
      margin: 0 36px;
      border-top: 1px solid #dedcda;
      height: 122px;
      overflow-y: auto;
      margin-bottom: 11px;
      .sc-item {
        height: 40px;
        border-bottom: 1px solid #dedcda;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sc-item-left {
          width: 60%;
          display: flex;
          align-items: center;
          .sc-item-left-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          .sc-item-left-name {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 42px;
          }
        }
        .sc-item-right {
          width: 35%;
          display: flex;
          align-items: center;
          .sc-item-right-select {
          }
          .sc-item-right-close {
            margin-right: 5px;
            width: 14px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.select-role-type {
  position: relative;
  .show-checked-type {
    position: absolute;
    left: 7px;
    top: 7px;
    width: 25px;
    height: 25px;
  }
}
.select-list {
  position: relative;
  .select-list-search {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
